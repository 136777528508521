@import "index";

body {
  font-size: 1.08em;
  margin: 0 0 0 16.5em;
  max-width: inherit;
  overscroll-behavior: none; }

body > article, body > footer {
  margin: 0 5em;
  padding: 2em 0;
  max-width: 50em; }

body > header {
  display: block;
  position: fixed;
  overflow: auto;
  background: darken($dark, 2%);
  color: #fff;
  border: none;
  box-shadow: none;
  opacity: .98;
  margin: 0;
  padding: 2.25em;
  width: 12em;
  top: 0;
  bottom: 0;
  left: 0; }

article header h1 {
  font-size: 2em; }

nav {
  margin: 0 -2.25em; }

nav a {
  display: block;
  margin: 0;
  padding: .5em 2.25em; }

nav a:hover, nav a.selected {
  background: lighten($dark, 2%);
  text-decoration: inherit; }

article:not(:last-child) {
  border-bottom-color: reduce(20); }

.icons svg {
  margin-right: 1.2em; }

.hidden {
  display: inline-block; }



.title {
  display: none; }





.description {
  margin: 2em 0; }


